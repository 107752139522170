.cta {

	.section-inner {
		padding-top: $cta--padding-t__mobile;
		padding-bottom: $cta--padding-b__mobile;
	}
}

.cta-slogan {
	margin-bottom: $cta-slogan--padding-v__mobile;
}

@include media( '>medium' ) {

	.cta {

		.section-inner {
			padding-top: $cta--padding-t__desktop;
			padding-bottom: $cta--padding-b__desktop;
		}
	}

	.cta-split {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.cta-slogan {
		margin-bottom: $cta-slogan--padding-v__desktop;

		.cta-split & {
			margin-bottom: 0;
			margin-right: $cta-slogan--padding-h__desktop;
		}
	}
}


.home-get-in-touch {
    width: 100%;
    display: flex;
    max-width: var(--dl-size-size-maxcontent);
    margin-top: var(--dl-space-space-fiveunits);
    align-items: center;
    padding-top: var(--dl-space-space-fiveunits);
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-fiveunits);
    justify-content: flex-start;
  }
  .home-text36 {
    text-align: center;
    margin-bottom: var(--dl-space-space-fourunits);
  }

  .home-message {
    width: 100%;
    border: none;
    height: 140px;
    padding-top: var(--dl-space-space-doubleunit);
    padding-left: var(--dl-space-space-tripleunit);
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: var(--dl-space-space-fourunits);
    padding-right: var(--dl-space-space-tripleunit);
    padding-bottom: var(--dl-space-space-doubleunit);
  }

  .home-locations-container {
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }

  .home-location-1 {
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-tripleunit);
    flex-direction: column;
  }

  .home-heading08 {
	margin-bottom: var(--dl-space-space-fourunits);
  }

  .home-heading09 {
    font-size: 24px;
    font-style: normal;
    font-family: Roboto;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .home-adress {
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-doubleunit);
    flex-direction: row;
  }

  .home-icon54 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-doubleunit);
  }
  .home-name5 {
	width: 100%;
	border: none;
	padding-top: var(--dl-space-space-doubleunit);
	padding-left: var(--dl-space-space-tripleunit);
	border-radius: var(--dl-radius-radius-radius8);
	margin-bottom: var(--dl-space-space-doubleunit);
	padding-right: var(--dl-space-space-tripleunit);
	padding-bottom: var(--dl-space-space-doubleunit);
  }

  .home-email {
	width: 100%;
	border: none;
	padding-top: var(--dl-space-space-doubleunit);
	padding-left: var(--dl-space-space-tripleunit);
	border-radius: var(--dl-radius-radius-radius8);
	margin-bottom: var(--dl-space-space-doubleunit);
	padding-right: var(--dl-space-space-tripleunit);
	padding-bottom: var(--dl-space-space-doubleunit);
  }
  .home-message {
	width: 100%;
	border: none;
	height: 140px;
	padding-top: var(--dl-space-space-doubleunit);
	padding-left: var(--dl-space-space-tripleunit);
	border-radius: var(--dl-radius-radius-radius8);
	margin-bottom: var(--dl-space-space-fourunits);
	padding-right: var(--dl-space-space-tripleunit);
	padding-bottom: var(--dl-space-space-doubleunit);
  }
  .home-cta-btn2 {
	color: var(--dl-color-gray-white);
	text-align: center;
	padding-top: var(--dl-space-space-unit);
	padding-left: var(--dl-space-space-tenunits);
	border-radius: var(--dl-radius-radius-radius24);
	padding-right: var(--dl-space-space-tenunits);
	padding-bottom: var(--dl-space-space-unit);
	background-color: var(--dl-color-gray-black);
  }
  .home-email1 {
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-doubleunit);
    flex-direction: row;
  }
  .home-icon56 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-doubleunit);
  }
  .home-phone {
    display: flex;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-doubleunit);
    flex-direction: row;
  }
  .home-icon58 {
    width: 24px;
    height: 24px;
    margin-right: var(--dl-space-space-doubleunit);
  }
  .home-content-container6 {
	width: 100%;
	display: flex;
	align-self: stretch;
	margin-top: var(--dl-space-space-fiveunits);
	align-items: center;
	flex-direction: row;
	justify-content: center;
  }

  .home-form-container {
	width: 40%;
	display: flex;
	align-self: stretch;
	align-items: center;
	padding-top: var(--dl-space-space-fiveunits);
	margin-right: var(--dl-space-space-fiveunits);
	padding-left: var(--dl-space-space-tenunits);
	border-radius: var(--dl-radius-radius-radius8);
	padding-right: var(--dl-space-space-tenunits);
	flex-direction: column;
	padding-bottom: var(--dl-space-space-fiveunits);
	background-color: var(--dl-color-gray-900);
  }
  